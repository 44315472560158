<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('businessList_shxq', { id: $route.query.id })"
        >基本信息</span
      >
      <span @click="toUrl('addcommercial_jsxx_edit', { id: $route.query.id })"
        >结算信息</span
      >
      <span @click="toUrl('addcommercial_qdxx_edit', { id: $route.query.id })"
        >渠道信息</span
      >
      <span @click="toUrl('addcommercial_pzxx_edit', { id: $route.query.id })"
        >产品信息</span
      >
      <span @click="toUrl('addcommercial_pzxx1', { id: $route.query.id })"
        >配置信息</span
      >
      <span
        id="fzcolor"
        @click="toUrl('addcommercial_dkxq_edit', { id: $route.query.id })"
        >终端信息</span
      >
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:PORT'">
      <div class="desc">
        <h5 class="jsxx">终端信息</h5>
      </div>

      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 120px">
              <p>终端号</p>
            </td>
            <td style="width: 120px">
              <p>终端名</p>
            </td>
            <td style="width: 120px">
              <p>终端类型</p>
            </td>
            <td style="width: 90px">
              <p>状态</p>
            </td>
            <td style="width: 120px">
              <p>二维码</p>
            </td>

            <td style="width: 120px">
              <div>门店名称</div>
              <div>门店编号</div>
            </td>

            <td style="width: 50px">
              <p>是否分账</p>
            </td>

            <td style="width: 200px">
              <div>分账商户</div>
              <div>商户编号</div>
            </td>
            <td style="width: 50px">
              <p>分账比例</p>
            </td>
            <td style="width: 120px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tableData" :key="i">
            <!-- 终端号 -->
            <td style="width: 120px">
              <p>{{ v.opTermNo }}</p>
            </td>
            <!-- 终端名 -->
            <td style="width: 120px">
              <p>{{ v.opTermName }}</p>
            </td>
            <!-- 终端类型 -->
            <td style="width: 120px">
              <p>{{ v.opTermTp == 1 ? "聚合码" : "" }}</p>
            </td>
            <!-- 状态 -->
            <td style="width: 90px">
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:PORT/EDIT'"> {{ v.status==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PORT/EDIT'">
                <el-switch
                  v-model="v.status"
                  @change="setStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <!-- 二维码 -->
            <td style="width: 120px">
              <p>
                <el-button
                  @click="previewPicture(1, v)"
                  type="text"
                  size="medium"
                  >预览</el-button
                >
              </p>
            </td>

            <!-- 门店名称/门店编号-->
            <td style="width: 120px">
              <div>{{ v.storeName }}</div>
              <div>{{ v.storeId }}</div>
            </td>
            <!-- 是否分账 -->
            <td style="width: 50px">
              <p>{{ v.isAllocate == 1 ? "是" : "否" }}</p>
            </td>

            <!-- 分账商户/商户编号 -->
            <td style="width: 200px">
              <div>{{ v.isAllocate == 1 ? v.allocMchName : "" }}</div>
              <div>{{ v.isAllocate == 1 ? v.allocMchId : "" }}</div>
            </td>
            <!-- 分账比例 -->
            <td style="width: 50px">
              <p>{{ v.isAllocate == 1 ? v.accountRatio / 100 + "%" : "/" }}</p>
            </td>

            <!-- 操作 -->
            <td style="width: 120px">
              <p>
                <el-button
                  type="text"
                  v-auth="'ACCOUNT:MCH:MCHS:PORT/EDIT'"
                  size="medium"
                  @click="openDialog(2, v)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="medium"
                  v-auth="'ACCOUNT:MCH:MCHS:PORT/DOWNLOAD'"
                  @click="downloadPic(v)"
                  >下载</el-button
                >
                <el-button
                  v-auth="'ACCOUNT:MCH:MCHS:PORT/DELETE'"
                  type="text"
                  size="medium"
                  @click="deleteTerminal(v)"
                  >删除</el-button
                >
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- 预览二维码弹框     -->
      <el-dialog :title="qrcodeTitle" :visible.sync="imgFlag" width="240px">
        <div class="imgBox">
          <div id="qrcode" ref="qrCodeUrl"></div>
        </div>
      </el-dialog>
      <div
        class="addText"
        v-auth="'ACCOUNT:MCH:MCHS:PORT/ADD'"
        @click="openDialog(1, {})"
      >
        + 新增终端信息
      </div>

      <!-- 新增编辑终端信息弹框 -->
      <el-dialog
        :title="dialogTitle + '终端信息'"
        :visible.sync="visibleFlag"
        width="490px"
      >
        <div class="dialogBody">
          <div class="bodyLeft">
            <div class="leftItem">
              <div class="inputName">
                <span>*</span>
                <div>终端名称</div>
              </div>
              <div class="inputBox">
                <el-input
                  v-model="formData.opTermName"
                  placeholder="请输入终端名称"
                />
              </div>
            </div>
            <div class="leftItem">
              <div class="inputName">
                <span>*</span>
                <div>所属门店</div>
              </div>
              <div class="inputBox">
                <el-select
                  v-model="formData.storeId"
                  placeholder="请选择门店"
                  style="width: 100%"
                >
                  <el-option
                    v-for="v in storeList"
                    :key="v.storeId"
                    :label="v.storeName"
                    :value="v.storeId"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="leftItem" v-if="formData.isAllocate == 1">
              <div class="inputName">
                <span>*</span>
                <div>分账比例 (90-100%)</div>
              </div>
              <div class="inputBox">
                <!-- <el-input
                  v-model="formData.accountRatio"
                  placeholder="请输入"
                /> -->
                <el-input
                  type="number"
                  onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
                  min="90"
                  max="100"
                  v-model="formData.accountRatio"
                  placeholder="请输入"
                  tabindex="5"
                ></el-input>
              </div>
            </div>
            <div class="leftItem" v-if="formData.isAllocate == 1">
              <div class="inputName">
                <div>收款码配置</div>
              </div>
              <div class="inputBox">
                 <el-checkbox v-model="wxpay" @change="changeWxpay" style="margin-left:15px;"></el-checkbox>
                 <span style="margin-left:5px;">微信logo展示</span>
              </div>
            </div>
          </div>
          <div class="bodyRight">
            <div class="rightItem">
              <div class="inputName">
                <span>*</span>
                <div>端口类型</div>
              </div>
              <div class="inputBox">
                <el-select
                  v-model="formData.opTermTp"
                  placeholder="请选择端口类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="v in portTypeList"
                    :key="v.value"
                    :label="v.label"
                    :value="v.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="rightItem">
              <div class="inputName">
                <span>*</span>
                <div>是否分账</div>
              </div>
              <div class="inputBox">
                <el-select
                  v-model="formData.isAllocate"
                  placeholder="请选择"
                  @change="changeSplit"
                  style="width: 100%"
                >
                  <el-option
                    v-for="v in splitList"
                    :key="v.value"
                    :label="v.label"
                    :value="v.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="rightItem" v-if="formData.isAllocate == 1">
              <div class="inputName">
                <span>*</span>
                <div>分账商户</div>
              </div>
              <div class="inputBox">
                <el-select
                  v-model="formData.allocMchId"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="v in allMchList"
                    :key="v.allocMchId"
                    :label="v.allocMchName"
                    :value="v.allocMchId"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="leftItem" v-if="formData.isAllocate == 1">
              <div class="inputName">
                <div style="opacity: 0;">收款码配置</div>
              </div>
              <div class="inputBox">
                 <el-checkbox v-model="alipay"  @change="changeAlipay"></el-checkbox>
                 <span style="margin-left:5px;">支付宝logo展示</span>
              </div>
            </div>
          </div>
        </div>

        <div slot="footer" class="dialog_footer">
          <el-button class="footer_left" @click="visibleFlag = false">取消</el-button>

          <el-button class="footer_right" @click="submit(dialogTitle)" :disabled="buttonFlag">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getTermList,
  storesList,
  getMchList,
  changeStatus,
  delTerminal,
  addOrEditTerminal,
} from "@/api/user/shopManager.js";
import QRCode from "qrcodejs2";
import { isTwoDecimal } from "@/utils/common.js";
export default {
  data() {
    return {
      buttonFlag: false, //防止重复提交
      tableData: [],
      imgFlag: false, //控制预览图片弹框
      dropResparam: {
        pageNo: 1,
        pageSize: 10,
      },
      mchId: "",
      storeList: [], //店铺列表下拉数据
      allMchList: [], //分账商户下拉数据
      dialogTitle: "新增", //新增编辑终端信息弹窗标题
      visibleFlag: false, //控制新增编辑终端信息弹框变量
      formData: {
        opTermName: "", //终端名称
        opTermNo: "", //端口号
        opTermTp: "", //终端类型
        storeId: "", //选择的门店ID
        isAllocate: "", //是否分账 1为是 0为否
        accountRatio: "", //分账比例
        allocMchId: "", // 分账商户编号
        wxpay:0, //配置微信logo
        alipay:0, //配置支付宝logo
      },
      //终端类型可选值
      portTypeList: [
        {
          label: "聚合码",
          value: 1,
        },
      ],
      // 是否分账可选值
      splitList: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      qrcodeTitle: "", //预览二维码弹框名称
      wxpay:false, //配置微信logo
      alipay:false, //配置支付宝logo
      OFFON_EDIT:'ACCOUNT:MCH:MCHS:PORT/EDIT',
    };
  },
  components: {
    QRCode,
  },
  created() {
    this.mchId = this.$route.query.id;
    this._getTermList(this.mchId); //获取终端列表
    this._getDropStoreList(this.mchId); //获取店铺下拉列表
    this._getMchList(this.mchId); //获取商户下拉列表
    this.listJurisdiction();
  },
  methods: {

    changeWxpay(val){
      console.log(val);
      if(val == true && this.wxpay == true){
        this.formData.wxpay = 1;
      }else{
        this.formData.wxpay = 0;
      }
    },

    changeAlipay(val){
      console.log(val);
      if(val == true && this.alipay == true){
        this.formData.alipay = 1;
      }else{
        this.formData.alipay = 0;
      }
    },

    // 跳转页面
    toUrl(name, query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
    // 获取终端信息
    _getTermList(mchId) {
      getTermList(mchId).then((res) => {
        if (res.resultStatus) {
          res.resultData.treminals.forEach((e) => {
            if (e.opTermStatus == 1) {
              e.status = true;
            } else {
              e.status = false;
            }
          });
          this.tableData = res.resultData.treminals;     
        }
      });
    },
    //链接生成二维码 Api
    toQrcode(val, url) {
      // let qrcode = new QRCode("qrcode", {
      //   width: 200,
      //   height: 200,
      //   text: url, //需要转换的链接
      //   // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      //   // background: '#f0f',   // 背景色
      //   // foreground: '#ff0'    // 前景色
      // });

      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      if (val == 2) {
        this.imgFlag = false;
      }

      console.log("二维码", qrcode);
    },

    // 点击预览
    previewPicture(val, item) {
      console.log("要转换的图片路径为", item);

      this.qrcodeTitle = item.opTermName;
      this.imgFlag = true;
      this.$nextTick(() => {
        document.getElementById("qrcode").innerHTML = ""; //先清空之前生成的二维码

        this.toQrcode(val, item.qrCode);
      });
    },

    // 下载二维码1
    downloadPic(v) {
      console.log("终端号为", v);
      this.previewPicture(2, v);
      // this.imgFlag = false;
      // 找到canvas标签
      this.$nextTick(() => {
        let myCanvas = document
          .getElementById("qrcode")
          .getElementsByTagName("canvas");
        let img = document.getElementById("qrcode").getElementsByTagName("img");
        // console.log("下载中22",img);

        // 创建一个a标签节点
        let a = document.createElement("a");
        // 设置a标签的href属性(将 canvas变成png图片)
        let imgURL = myCanvas[0].toDataURL("image/jpg");
        let ua = navigator.userAgent;
        // return
        if (ua.indexOf("Trident") != -1 && ua.indexOf("Windows") != -1) {
          // console.log("IE内核并且Windows系统情况下才执行", ua);
          //IE内核并且Windows系统情况下才执行
          var bstr = atob(imgURL.split(",")[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          var blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            "终端号" + "-" + v.opTermNo + ".png"
          );
        } else if (ua.indexOf("Firefox") > -1) {
          //火狐兼容下载
          let blob = this.base64ToBlob(imgURL);
          let evt = document.createEvent("HTMLEvents");
          evt.initEvent("click", true, true);
          a.download = "火狐"; //下载图片名称,如果填充内容识别不到,下载为未知文件
          a.href = URL.createObjectURL(blob);
          a.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        } else {
          //谷歌兼容下载
          img.src = myCanvas[0].toDataURL("image/jpg");
          a.href = img.src;

          //  设置下载文件的名字 为终端号
          a.download = "终端号" + "-" + v.opTermNo;
          a.click();
        }
      });
    },

    // base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },

    //点击新增终端信息,打开弹窗
    openDialog(val, row) {
      // console.log("新增或编辑时aa", val);
      // console.log("新增或编辑时BB", row);
      //  return
      if (val == 1) {
        for (var i in this.formData) {
          this.formData[i] = "";
        }
         this.dialogTitle = "新增";
      } else {
        this.dialogTitle = "编辑";
        this.formData.opTermName = row.opTermName;
        this.formData.opTermNo = row.opTermNo;
        this.formData.opTermTp = row.opTermTp;
        this.formData.storeId = row.storeId;
        this.formData.isAllocate = row.isAllocate;
        if(row.accountRatio == null || row.accountRatio == 0){
          this.formData.accountRatio=""
        }else{
          this.formData.accountRatio = row.accountRatio/100;
        }
        this.formData.allocMchId = row.allocMchId;

        this.formData.wxpay =  row.wxpay;
        this.formData.alipay =  row.alipay;

        this.wxpay = this.formData.wxpay == 1 ? true : false;
        this.alipay = this.formData.alipay == 1 ? true : false;       
      }
      this.visibleFlag = true;
      // console.log("新增或编辑时BB",  this.dialogTitle);

    },
    // 根据商户id获取店铺下拉列表
    _getDropStoreList(mchId) {
      storesList(mchId).then((res) => {
        if (res.resultStatus) {
          let arry = res.resultData.stores;
          arry.forEach((e) => {
            e.storeName = e.storeName + " " + "|" + " " + e.storeId;
          });
          this.storeList = arry;
          // if (this.tabData.length == 0) {
          //   this.$message({
          //     message: "暂无数据",
          //     duration: 1500,
          //   });
          // }
        }
      });
    },
    // 根据商户id获取分账商户下拉列表
    _getMchList(mchId) {
      getMchList(mchId).then((res) => {
        if (res.resultStatus) {
          let arry = res.resultData.allocMchs;
          arry.forEach((e) => {
            e.allocMchName = e.allocMchName + " " + "|" + " " + e.allocMchId;
          });
          this.allMchList = arry;
          // if (this.tabData.length == 0) {
          //   this.$message({
          //     message: "暂无数据",
          //     duration: 1500,
          //   });
          // }
        }
      });
    },
    // 切换是否分账时
    changeSplit(e) {
      console.log("切换是否分账时", e);
      if (e == 0) {
        // 选择否时  清空输入的分账比例 和选择的分账商户
        this.formData.accountRatio = "";
        this.formData.allocMchId = "";
      }
    },
    // 切换状态
    setStatus(row) {
      // console.log("切换状态", row);
      // return;
      let opTermNo = row.opTermNo;
      changeStatus(opTermNo).then((res) => {
        if (!res.resultStatus) {
          //如果接口调取失败的话 发布状态维持现状
          row.status = !row.status; //接口调取失败时 不可改变状态
        } else {
          this.$message.success(row.status ? "启用成功" : "关闭成功");
        }
      });
    },
    //删除终端
    deleteTerminal(row) {
      this.$confirm("确定删除此终端?", "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "confirmButton", //确定按钮的自定义类名
        cancelButtonText: "取消",
        cancelButtonClass: "cancelButton", //取消按钮的自定义类名
        type: "warning",
        closeOnClickModal: true, //是否可通过点击遮罩关闭
        closeOnPressEscape: false, //是否可通过按下 ESC 键关闭
      })
        .then(() => {
          //确定保存 调取提交删除接口
          delTerminal(row.opTermNo).then((res) => {
            if (res.resultStatus) {
              this.$message.success("删除成功");
              this._getTermList(this.mchId); //重新获取终端列表
            }
          });
        })
        .catch(() => {});
    },

    // 验证1-100正整数
    isTrueNum(Num) {
      const reg = /^[1-9]\d{0,2}$/;
      return reg.test(Num);
    },
    rules() {
      const data = JSON.parse(JSON.stringify(this.formData));
      // console.log("充值表单提交的数据",data);

      // return
      if (!data.opTermName) {
        this.$message.error("终端名称必填");
        return false;
      }
      if (!data.opTermTp) {
        this.$message.error("终端类型必选");
        return false;
      }

      if (!data.storeId) {
        this.$message.error("所属门店必选");
        return false;
      }
      if (typeof data.isAllocate == "string") {
        this.$message.error("是否分账必选");
        return false;
      } else if (data.isAllocate == 1) {
        // if (!data.accountRatio) {
        //   this.$message.error("请输入分账比例");
        //   return false;
        // } else {
        //   if (!this.isTrueNum(data.accountRatio)) {
        //     this.$message.error("请输入1-100正整数");
        //     return false;
        //   } else {
        //     if (data.accountRatio > 100) {
        //       this.$message.error("请输入1-100正整数");
        //       return false;
        //     }
        //   }
        // }
        if (!data.accountRatio || data.accountRatio < 90 || data.accountRatio > 100) {
            this.$message.error("分账比例必填且大于等于90%，小于等于100%");
            return false;
        } else {
            if (!isTwoDecimal(data.accountRatio)) {
              this.$message.error("分账比例不合法(最多保留小数点后两位)");
              return false;
            }
        }
        if (!data.allocMchId) {
          this.$message.error("分账商户必选");
          return false;
        }
      }

      return true;
    },

    // 新增或编辑终端信息点确定时
    submit(title) {
      // console.log("编辑时候提交的参数为", this.formData);
      // return;
      if (this.rules()) {
        let opTermNo = "";

        if (title == "新增") {
          opTermNo = 0;
        } else {
          opTermNo = this.formData.opTermNo;
        }
   
        let params = {
          opTermNo,
          opTermName: this.formData.opTermName,
          opTermTp: this.formData.opTermTp,
          storeId: this.formData.storeId,
          isAllocate: this.formData.isAllocate,
          accountRatio: this.formData.accountRatio*100,
          allocMchId: this.formData.allocMchId,
          wxpay:this.formData.wxpay,
          alipay:this.formData.alipay,
        };
        this.buttonFlag = true;
        let arr = !Object.values(this.formData).every((item) => item === ""); //防止重复新增
        if (arr) {
          this._addOrEditTerminal(this.mchId, params);
        } else {
          this.$message.error("请勿重复点击");
        }
      }
    },
    _addOrEditTerminal(mchId, params) {     
      addOrEditTerminal(mchId, params).then((res) => {
        if (res.resultStatus) {
          if (this.dialogTitle == "新增") {
            this.$message.success("新增终端成功");
          } else {
            this.$message.success("编辑终端成功");
          }
          this.wxpay = false;
          this.alipay = false;
          this.visibleFlag = false;
          this._getTermList(this.mchId); //重新获取终端列表
        }
        setTimeout(() => {
            this.buttonFlag = false;
        }, 2000);
      });
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:PORT/EDIT';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PORT/EDIT') !== -1 ){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:PORT/EDIT';
          }else{
            this.OFFON_EDIT = "";
          }
        }

    },

  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.tab tr td div {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.addText {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(72, 184, 182, 1);
  cursor: pointer;
}
.imgBox {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialogBody {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.bodyLeft {
  flex: 0 0 50%;
  /* background-color: pink; */
}
.leftItem {
  padding: 0 20px;
  margin-bottom: 20px;
  /* background-color: pink; */
}
.inputName span {
  color: #48b8b6;
  font-size: 15px;
  margin-right: 5px;
}
.inputName {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.inputBox {
  width: 200px;
}
.bodyRight {
  flex: 0 0 50%;
}
.rightItem {
  padding: 0 20px;
  margin-bottom: 20px;
}

.dialog_footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
}
.footer_left,
.footer_right {
  width: 60px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.footer_left {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.footer_right {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

</style>